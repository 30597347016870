import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Control from './Control/Control';

import { StyledSliderWrap, StyledSliderInner } from './styled';

const Slider = ({ children, clearPadding, controlsStyle, ...props }) => {
  const controlProps = { clearPadding, controlsStyle };
  const DEFAULT_PROPS = {
    dots: false,
    infinite: true,
    speed: 200,
    rows: 3,
    slidesPerRow: 3,
    prevArrow: <Control variant="left" {...controlProps} />,
    nextArrow: <Control variant="right" {...controlProps} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          rows: 3,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  const sliderProps = { ...DEFAULT_PROPS, ...props };

  return (
    <StyledSliderWrap clearPadding={clearPadding}>
      <StyledSliderInner>
        <SlickSlider {...sliderProps}>{children}</SlickSlider>
      </StyledSliderInner>
    </StyledSliderWrap>
  );
};

Slider.propTypes = {
  children: PropTypes.object, // esling-disable-line
};

export default Slider;
