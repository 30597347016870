import styled from 'styled-components';

export const StyledSliderWrap = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin: 0 auto 20px;
  padding: ${({ clearPadding }) => clearPadding ? 0 : '30px'};
  max-width: 100%;
`;
export const StyledSliderInner = styled.div`
  position: relative;

  & .slick-slide > div {
    display: flex;
    justify-content: center;
  }

  & .slick-slide img {
    margin: 0 auto;
  }
`;
