import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

const Control = styled.button`
  display: none;
  padding: 0;
  position: absolute;
  top: calc(50% - 17px);
  width: 1.9rem;
  height: 3.4rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 11;

  &::before {
    content: '' !important;
    position: absolute;
    top: 0.4rem;
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid #ffffff;
    border-top-style: none;
    border-right-style: none;
  }

  ${MEDIA.TABLET_XL`
    display: block;
  `}

  ${({ variant }) => {
    switch (variant) {
      case 'left':
        return css`
          ${({ clearPadding }) => clearPadding && css`left: 30px !important;`}
          &::before {
            transform: rotate(45deg);
            left: 0.4rem;
          }
        `;
      case 'right':
        return css`
          ${({ clearPadding }) => clearPadding && css`right: 30px !important;`}
          &::before {
            transform: rotate(-135deg);
            left: -1rem;
          }
        `;
    }
  }}

  ${({ controlsStyle }) => controlsStyle || ''}
`;

export default Control;
